import { createPopper, Instance, Options, Placement } from "@popperjs/core";
import React, { useRef, useEffect, MutableRefObject } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import useDeepMemo from "hooks/ui/useDeepMemo";
type PopperProps = {
  zIndex: number;
  isOpen: boolean;
  targetNode?: Element;
  children: JSX.Element;
  placement: Placement;
  allowedAutoPlacements?: Placement[];
  modifiers?: Options["modifiers"];
  // Set to false to disable default modifiers like 5px offset
  useDefaultModifiers?: boolean;
  extraSuperblocksAttr?: string;
  onCreate?: (instance: Instance) => void;
  mountingNode?: Element;
  // A list of dependencies to schedule position update on
  dependencies?: any[];
};

const PopperWrapper = styled.div<{ zIndex: number }>`
  z-index: ${(props) => props.zIndex};
  position: absolute;
`;

/* eslint-disable react/display-name */
const Popper = React.forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
  const { onCreate, dependencies = [] } = props;

  const localRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const popperRef = useRef<Instance | null>(null);
  const memoizedModifiers = useDeepMemo(
    () => props.modifiers,
    [props.modifiers],
  );

  useEffect(() => {
    const parentElement = props.targetNode && props.targetNode.parentElement;
    if (
      parentElement &&
      parentElement.parentElement &&
      props.targetNode &&
      props.isOpen
    ) {
      // TODO: To further optimize this, we can go through the popper API
      // and figure out a way to keep an app instance level popper instance
      // which we can update to have different references when called here.
      // However, the performance benefit gained by such an optimization
      // remaines to be discovered.
      const defaultModifiers =
        typeof props.useDefaultModifiers !== "undefined" &&
        !props.useDefaultModifiers
          ? []
          : [
              {
                name: "flip",
                options: {
                  allowedAutoPlacements: props.allowedAutoPlacements ?? [
                    "right",
                    "left",
                    "bottom",
                    "top",
                  ],
                  // Default from v1
                  padding: 5,
                },
              },
              {
                name: "offset",
                options: {
                  // Default from v1
                  offset: [0, 5],
                },
              },
            ];
      const _popper = createPopper(
        props.targetNode,
        localRef.current as unknown as HTMLElement,
        {
          placement: props.placement,
          modifiers: [...defaultModifiers, ...(memoizedModifiers ?? [])],
        },
      );
      popperRef.current = _popper;
      onCreate && onCreate(_popper);
      return () => {
        popperRef.current = null;
        _popper.destroy();
      };
    }
  }, [
    props.targetNode,
    props.isOpen,
    memoizedModifiers,
    props.placement,
    ref,
    onCreate,
    props.useDefaultModifiers,
    props.allowedAutoPlacements,
  ]);

  useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return createPortal(
    <PopperWrapper
      // there is a selector & > *:not(.popper-wrapper) that is used to add box shadow in root canvas
      className="popper-wrapper"
      ref={(node) => {
        localRef.current = node;
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      zIndex={props.zIndex}
      data-test="popper"
      data-popper-index={props.extraSuperblocksAttr}
    >
      {props.children}
    </PopperWrapper>,
    props.mountingNode ?? document.body,
  );
});

export default Popper;
